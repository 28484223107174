.home-wrap{width:100%;margin:0 auto; box-sizing:border-box;}
/* Banner-section */
.home-wrap .banner-section{display: flex;padding:0 30px;}
.home-wrap .banner-section .content{padding:0 40px 0 0;flex:0 0 50%;}
.content .about{font-size: 24px;color: skyblue;}
.content .heading{font-size: 42px;line-height: 50px;font-weight: 600;color: #294762;margin:15px 0;}
.content .c-jtech{font-size: 16px;line-height:26px;color:cadetblue;font-weight: 500;}
.content .but{display: flex;flex-direction: column;row-gap: 15px;  margin:15px 0 0;}
.but .call{font-size: 25px;color: skyblue;}
.but .num{color: black;}
.content .btn-wrap{margin:20px 0 0;}
.content .btn-wrap .req-btn{padding: 0 20px; height: 40px; border-radius: 10px; color: whitesmoke; cursor: pointer; background-color: skyblue; border: none; display: flex; align-items: center; justify-content: center; width: max-content;}
.photo{flex:0 0 50%;}
.photo img{width: 100%;border-radius: 50px; vertical-align: middle;transition: .2s all;animation: up-down 1.5s ease-in-out infinite alternate-reverse both;}
.btn-wrap .req-btn{padding: 10px;}

/*  business-detail-section */
.business-detail-section{display: flex;flex-direction: column;padding:40px 30px;}
.business-detail-section .business-heading{color: #294762;font-size: 22px;font-weight: 700;text-align:left;}
.business-detail-section .lists{display: flex;text-align: left;flex-direction: column;row-gap:10px;list-style:decimal;padding: 20px;}
.business-detail-section .lists .list{color:cadetblue;font-size: 16px;line-height:25px;}
.business-detail-section .lists .list span{font-size:18px;}

/* business-card */
.business-card{width: 100%;box-sizing: border-box;margin: 0 auto;padding:0 30px;}
.business-card .card-wrap{display: flex;flex-wrap:wrap;column-gap:30px;}
.business-card .card-wrap .card{width:calc(33.33% - 20px);background-color:#f2f1fa;border-radius: 20px;flex-direction: column;row-gap:20px;display: flex;cursor: pointer;box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);padding: 50px 20px;transition:.2s all;}
.business-card .card-wrap .card .title{font-size: 22px;color:#294762;font-weight: 600;}
.business-card .card-wrap .card .desc{color: cadetblue; font-size: 14px;line-height:20px;}
.business-card .card-wrap .card:hover{color: cadetblue;outline:auto;}


/* recruitment-section */
.recruitment-section{padding:40px 0;margin:0 auto;}
.recruitment-section .heading{text-align:center;margin:0 0 20px;}
.heading .head1{font-size: 22px;color: red;font-weight: 600;}
.heading .head2{font-size: 42px;color: black;font-weight:700;}
.box-wrap{flex-wrap:wrap;display: flex;column-gap:30px;}
.box-wrap .box-item{width:calc(25% - 23px);border-radius: 20px;flex-direction: column;row-gap: 30px;display: flex;color:red;cursor: pointer;text-align: justify;position: relative;}
.box-wrap .box-item .layout{  left: 0;bottom: 0;right: 0;background-color:#00000061;}
.box-wrap .box-item .un{color: orange;font-size: 24px;}
.box-wrap .visibility{text-align: start;     transition: .2s all; animation: up-down 1.5s ease-in-out infinite alternate-reverse both; position: relative;bottom: 266px;font-size: 18px; color:teal;font-weight: 600;right: 3px;padding:10px;}
.visibility:hover{color: red;width: 100%;border-radius: 10px;transition: all 500ms ease;}
.box-wrap .box-item img{width: 100%;border-radius: 10px;width: 100%;border-radius: 10px;transition: all 500ms ease;}
.box-item .word{display: flex;flex-direction: column;align-items: center;row-gap: 20px;position: absolute;right: 30px;left: 30px;top: 280px;height:29px;}

/* expert-section */
.expert-section{margin:0 auto;column-gap: 40px;justify-content: space-between;display: flex;box-sizing: border-box;padding:30px;}
.expert-section .left{display: flex;flex-direction: column;}
.expert-section .right{position: relative;flex:0 0 40%;}
.expert-section .right .im1{position: absolute;top: 400px;right: 119px;}
.expert-section .right .img-1{width: 500px;height: 650px;border-radius: 20px;}
.expert-section .right .img-2{width: 450px;height: 300px;border-radius: 20px;transition:.2s all;}
.expert-section .right .img-2:hover{cursor: pointer;transform: rotate(18deg);box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);}
.expert-section .left .title{color: red;font-size: 22px;line-height:32px;font-weight: 600;}
.expert-section .left .heading{color: black;font-size: 42px;font-weight: 600;line-height: 50px;}
.expert-section .left .sub-heading{color: red;font-size: 18px;line-height:26px;margin: 20px 0;}
.expert-section .left .desc{color: cadetblue;font-size: 16px;line-height:26px;width: 85%;}

/* services-offer-section */
.services-offer-section{padding:30px;box-sizing: border-box;width: 100%;margin: 0px auto;}
.services-offer-section .servivces-heading{margin:0 0 20px;}
.services-offer-section .servivces-heading .heading{color: red;font-size:30px;}
.services-offer-section .servivces-heading .sub-heading{color: black;font-size:42px;font-weight:700;}
.services-offer-section .services-wrap{display: flex;flex-wrap:wrap;column-gap:30px;}
.services-item .head{color:skyblue;font-size: 18px;margin-bottom:10px;}
.services-offer-section .services-wrap .services-item{width:calc(33.33% - 20px);background-color: whitesmoke;padding: 12px;object-fit: contain;border: 1px solid skyblue;border-radius: 10px;cursor: pointer;}
.services-wrap .services-item:hover{color: red;}
.services-offer-section .services-wrap .services-item img{width: 100%;height: 300px;border-radius: 10px;}
.services-item img:hover{transform: rotate(10deg);}
.services-item .info{display: flex;flex-direction: column;justify-content: center;align-items: center;margin-top:20px;}
.services-item .info .head-1{font-size: 14px;text-align: center;color: cadetblue;}


/* contact-us-section */
.contact-us-section{width: 100%;box-sizing: border-box;display: flex;margin: 80px auto;border-radius: 20px;justify-content: space-between;background-color: #fc6539;}
.home-wrap .contact-us-section .right img{width: 300px;display: flex;height: 300px;border-radius: 0 20px 20px 0;}
.contact-us-section .left{display: flex;align-items: center;padding:34px;}
.contact-us-section .left .heading{font-size: 42px;color: white;font-weight: 700;width: 600px;}
.contact-us-section .left .btn-wrap{padding: 0 20px;height: 40px;border-radius: 10px;color: whitesmoke;cursor: pointer;background-color: white;border: none;display: flex;align-items: center;justify-content: center;width: max-content;}
.contact-us-section .left .btn-wrap .req-btn{color: red;font-weight: 500;}

 @media(max-width:1176px){
 }
@media(max-width:991px){
  /* Banner-section */
  .home-wrap .banner-section{padding:30px;}
  .home-wrap .banner-section .content{padding:0 10px 0 0;}

  /*  business-detail-section */
  .business-detail-section{padding:0 30px;}

  /* business-card */
  .business-card{padding:0 30px;}

  .business-card .card-wrap .card .title{font-size: 16px;}

  /* recruitment-section */
  .recruitment-section{padding:30px;}
  .box-wrap{gap:20px;}
  .box-wrap .box-item{width:calc(33.33% - 14px);}
  .box-wrap .box-item img{width: 100%;}
  .box-item .word{row-gap:60px;top: 249px;}

  /* expert-section */
  .expert-section{padding:30px;}
  .expert-section .right .im1{position: absolute;top:250px;right: 112px;}
  .expert-section .right .img-1{width: 430px;height:490px;}
  .expert-section .right .img-2{width:360px;height:264px;}

  /* services-offer-section */
  .services-offer-section{padding:30px;}
  .services-offer-section .servivces-heading{margin:0 0 15px;}
  .services-offer-section .services-wrap{display: flex;flex-wrap:wrap;column-gap:20px;}
  .services-item .head{color:skyblue;font-size: 18px;margin-bottom:10px;}
  .services-offer-section .services-wrap .services-item{width:calc(33.33% - 14px);padding: 12px;object-fit: contain;}


  /* opportunity-section */

  /* contact-us-section */
  .contact-us-section{margin:60px auto;}
  .home-wrap .contact-us-section .right img{width: 300px;display: flex;height: 300px;border-radius: 0 20px 20px 0;}
  .contact-us-section .left{padding:25px;}
  .contact-us-section .left .heading{font-size:32px;}
  .contact-us-section .left .btn-wrap{width:160px;height:50px;}

}
@media(max-width:767px){
  /* Banner-section */
  .home-wrap .banner-section{flex-direction:column;row-gap:30px;padding:0 30px;}
  .content .about{font-size:22px;}
  .content .heading{font-size:36px;line-height:44px;margin:10px 0;font-weight: 700;}
  .content .c-jtech{font-size:14px;line-height:24px;font-weight: 500;}
  .content .but{row-gap: 10px;  margin:10px 0 0;}
  .but .call{font-size: 24px;color: skyblue;}
  .content .btn-wrap{margin:10px 0 0;text-align:end;}
  .photo{flex:0 0 50%;}
  .photo img{width: 100%;}

  /*  business-detail-section */
  .business-detail-section{padding:40px 30px;}
  .business-detail-section .business-heading{font-size:18px;}
  .business-detail-section .lists{row-gap:10px;padding:18px;}
  .business-detail-section .lists .list{font-size:14px;line-height:22px;}
  .business-detail-section .lists .list span{font-size:16px;}

  /* business-card */
  .business-card{padding:15px 0;}
  .business-card .card-wrap{gap:15px;padding: 0 30px;}
  .business-card .card-wrap .card{width:calc(50% - 8px);padding:35px 20px;}
  .business-card .card-wrap .card .title{font-size: 18px;color:#ff0000;}

  /* recruitment-section */
  .recruitment-section{padding:15px 0;}
  .recruitment-section .heading{margin:0 0 15px;}
  .layout{background-color:#00000000;}
  .heading .head1{font-size:18px;}
  .heading .head2{font-size:24px;}
  .box-wrap{gap:20px;}
  .box-wrap .box-item{width:calc(50% - 10px);}
  .box-wrap .box-item .un{font-size:22px;}
  .box-wrap .visibility{font-size:16px;}
  .visibility:hover{color: red;width: 100%;border-radius: 10px;transition: all 500ms ease;}
  .box-wrap .box-item img{width: 100%;border-radius: 10px;width: 100%;border-radius: 10px;transition: all 500ms ease;}
  .box-item .word{row-gap:60px;top: 249px;}

  /* expert-section */
   .expert-section{padding:15px 30px;flex-direction:column;}
   .expert-section .right{position: relative;flex:0 0 40%;}
   .expert-section .right .im1{position: absolute;top:166px;right:-5px;}
   .expert-section .right .img-1{width: 474px;height:401px;}
   .expert-section .right .img-2{width:360px;height:264px;}
   .expert-section .right .img-2:hover{cursor: pointer;transform: rotate(18deg);box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);}
   .expert-section .left .title{font-size:18px;text-align:center;}
   .expert-section .left .heading{font-size:36px;line-height:45px;text-align:center;}
   .expert-section .left .sub-heading{font-size: 18px;margin: 10px 0;}
   .expert-section .left .desc{font-size:14px;width:100%;margin-bottom:10px;}

  /* services-offer-section */
  .services-offer-section{padding: 30px;}
  .services-offer-section .servivces-heading{margin:0 0 15px;}
  .services-offer-section .services-wrap{display: flex;flex-wrap:wrap;gap:20px;}
  .services-item .head{color:skyblue;font-size: 18px;margin-bottom:10px;}
  .services-offer-section .services-wrap .services-item{width:calc(50% - 10px);padding: 12px;object-fit: contain;}
  .services-offer-section .servivces-heading{margin:0 0 15px;}
  .services-offer-section .servivces-heading .heading{font-size:18px;}
  .services-offer-section .servivces-heading .sub-heading{font-size:36px;}
  .services-item .info{margin-top:10px;}

  
  /* opportunity-section */

  /* contact-us-section */
  .contact-us-section{margin:30px auto;}
  .home-wrap .contact-us-section .right img{width:270px;display: flex;height:205px;}
  .contact-us-section .left{padding:15px;flex-direction:column;align-items: center;justify-content: center;}
  .contact-us-section .left .heading{font-size:20px;}
  .contact-us-section .left .btn-wrap{width:170px;height:50px;}
  .contact-us-section .left .btn-wrap .req-btn{color: red;}

}
  
@media(max-width:576px){
  .services-offer-section .services-wrap .services-item{width:100%;}
  .business-card .card-wrap .card{width:100%;padding:35px 20px;}
  .box-wrap{gap:15px;}
  .box-wrap .box-item{width:100%;}
  .expert-section .right .im1{position: absolute;top:172px;right:-5px;}
  .expert-section .right .img-1{width:416px;height:356px;}
  .expert-section .right .img-2{width:313px;height:218px;}
  
    /* contact-us-section */
    .contact-us-section .left{padding:15px;flex-direction:column;align-items:flex-start;justify-content: center;}
    .contact-us-section .left .heading{font-size:16px;}
    .contact-us-section .left .btn-wrap{width:100px;height:35px;font-size:14px;margin-top:15px;}
}
@media(max-width:479px){
  .content .about{font-size:18px;}
  .content .heading{font-size:24px;line-height:34px;}
  .but .call{font-size:18px;}
  .expert-section .right .im1{position: absolute;top:80px;right:-5px;}
  .expert-section .right .img-1{width:265px;height:241px;}
  .expert-section .right .img-2{width:261px;height:205px;}
  .home-wrap .contact-us-section .right img{width:196px;height:182px;}
  .contact-us-section .left .heading{font-size:14px;}
  .contact-us-section .left .btn-wrap{width:95px;height:35px;}
  .expert-section .left .heading{font-size:24px;line-height:34px;}
  .services-offer-section .servivces-heading{margin:0 0 20px;}
  .services-offer-section .servivces-heading .heading{font-size:18px;}
  .services-offer-section .servivces-heading .sub-heading{font-size:24px;}
}
