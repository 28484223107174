.testimonial-wrap{width:100%;margin:0 auto;padding:20px;box-sizing:border-box;}
.testimonial-wrap .testimonial-client{display: flex;align-items: center;column-gap:40px;}
.testimonial-wrap .testimonial-client .testimonial-left {flex:1;background-image:url("/public/images//shape-team-1.png");background-repeat:no-repeat;}
.testimonial-left .heading{color: cornflowerblue;font-size: 20px;}
.testimonial-left .testimonial-subtitle{padding: 30px;font-size: 20px;width: 70%;font-weight: 300;}
.testimonial-left .testimonial-subtitle span{font-weight: 900;}
.testimonial-arrows{display: flex;flex-direction: row;column-gap: 10px;align-items: center;justify-content: center;}
.testimonial-arrows .arrow-one,
.testimonial-arrows .arrow-two{background-color: cornflowerblue;border-radius: 50%;width: 50px;height: 50px;font-weight: 900;color: white;display: flex;justify-content: center;align-items: center;cursor: pointer;}
.testimonial-wrap .testimonial-client .testimonial-right{flex:1;background-color: #f2faff;border-radius: 20px;padding: 40px 0px;overflow:hidden;}
.testimonial-right .testimonial-review-wrapper{max-width:550px;width:100%;margin:0 auto;box-sizing: border-box;}
.testimonial-review-wrapper .review-data-wrap {text-align:center;}
.testimonial-review-wrapper .review-data-wrap .name{color: black;font-size: 14px;font-weight: 900;}
.testimonial-review-wrapper .review-data-wrap .post{}
.testimonial-review-wrapper .review-data-wrap .address{font-size:14px;}
.world {font-size: 28px;font-weight: 800;padding:20px;}
.business-lifecycle {text-align: center;padding:20px 0;overflow:hidden;}
.business-lifecycle .lifecycle-heading {font-size: 20px;font-weight: 400px;}
.business-lifecycle .process-items {display: flex;column-gap:30px;justify-content: center;align-items: center;padding: 20px;flex-wrap:wrap;}
.business-lifecycle .process-items .process-item {font-size: 20px;line-height: 40px;font-weight: 600;position: relative;z-index: 0;color: black;margin-bottom: 20px;width:calc(16.66 - 10px);}
.business-lifecycle .process-items .process-item:after {position: absolute;left: 50%;top: -13px;width: 70px;height: 60px;content: "";background-image: url(../../public/images/work-process-1.svg);background-repeat: no-repeat;background-position: center;transform: translateX(-50%);z-index: -1;}
.business-lifecycle .process-items .process-item .process-title {color: black;font-weight: 500px;}
.client-feedback{display: flex;flex-wrap:wrap;column-gap:40px;padding:20px 0;}
.client-feedback .feedback-item {width:calc(50% - 20px);}
.client-feedback .feedback-item .feedback-title {font-size: 20px;text-align:center;}
.client-feedback .feedback-item .feedback-text {text-align: justify;padding: 20px;align-items: center;color:cadetblue;}
.client {color: black;font-weight: 600;font-size: 28px;padding: 20px;text-align: center;}
.client-slider{padding:20px 0;}
.client-slider .client-item{display: flex;position: relative;height: 60px;justify-content: center;}
.client-slider .client-item .client-img{position: absolute;height: 100%;width: 100%;inset: 0px;object-fit: contain;color: transparent;transition: all 0.2s ease 0s;}
.client-slider .client-item .client-img:hover{transform:scale(1.08);z-index:1;}

@media(max-width:991px){
    .testimonial-wrap .testimonial-client{column-gap:30px;}
    .testimonial-left .testimonial-subtitle{padding:15px;font-size:18px;width:100%;}
    .testimonial-wrap .testimonial-client .testimonial-right{padding:20px;}
    .client-feedback{column-gap:30px;}
}
@media(max-width:767px){
    .testimonial-wrap .testimonial-client{flex-direction:column;gap:30px;}
    .testimonial-left .testimonial-subtitle{font-size:16px;}
    .testimonial-wrap .testimonial-client .testimonial-right{padding:30px;max-width:-webkit-fill-available;}
    .testimonial-arrows .arrow-one,
    .testimonial-arrows .arrow-two{width:36px;height:37px;}
    .testimonial-right .testimonial-review-wrapper{max-width:460px;text-align:center;}
    .world {font-size:20px;padding:15px 0px;text-align:center;}
    .client-feedback{gap:20px;padding:15px 0;flex-direction:column;}
    .client-feedback .feedback-item {width:100%;}
    .client-feedback .feedback-item .feedback-title {font-size:18px;}
    .client-feedback .feedback-item .feedback-text {padding: 20px;}
    .client {font-size:18px;padding:15px 0;}
    .client-slider{padding:15px 0;}

}
@media(max-width:479px){
    .testimonial-left .heading{font-size:18px;}
    .testimonial-left .testimonial-subtitle{font-size:14px;}
    .testimonial-wrap .testimonial-client .testimonial-right{padding:15px;}
    .testimonial-right .testimonial-review-wrapper .review-data{font-size:14px;line-height:22px;}
    .world {font-size:18px;padding:5px 0;}
    .client-feedback .feedback-item .feedback-text {padding:5px 15px;font-size:14px;}
}