.header-wrap {width: 100%;margin: 10px auto;padding: 0 30px;display: flex;align-items: center;justify-content: space-between;column-gap: 20px;box-sizing: border-box;}
.heading{color:black; font-size: 25px; transition:.3s all;font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;}
.header-wrap .heading:hover {color:#87ceeb;}
.nav-items {display:flex;justify-content:right;column-gap:40px;}
.header-wrap .logo-wrap  {display: flex;  flex-direction: column;align-items: center;}
.logo-wrap .text {font-size:14px;  font-weight:500;color:#87ceeb;font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;margin-left:10px;}
.nav-items .nav .link{color:black;transition:.3s all;font-size:16px;font-weight: 700;}
.nav-items .nav .link:hover {color:#87ceeb;}
.nav-items .active .link{color:red;}
.toggle-button {display:none;height:35px;border:none;border-radius:4px;transition:all 0.3s linear;background-color:#87ceeb;padding:5px;}

@media (max-width:991px) {
    .nav-items {column-gap:25px;}
    .heading{font-size:28px;}
}

@media (max-width:767px) {
    .heading{font-size:24px;}
    .nav-items {column-gap:20px;}
    .nav-items.close{display:none;}
    .toggle-button{display:block;align-items:center;}
    .nav-items.open{display:flex;flex-direction:column;align-items:start;row-gap:15px;box-shadow:#707070 0px 0px 5px 0px;border-radius:5px;padding:20px;position:absolute;top:95px;right:20px;left:20px;z-index:999;background-color:#fff;}
    .logo-wrap .text {margin-left:5px;}
}

