.privacy-policy{width:100%;margin: 0 auto;padding:20px;}
.privacy-policy .heading{color: red;font-size:24px;}
.privacy-policy {color:#000;font-size: 16px;line-height:24px;}
.privacy-policy p{margin:10px 0;}
.privacy-policy p em{font-weight:600;}
.privacy-policy p ul{list-style-type:disclosure-closed;display:flex;flex-direction:column;row-gap:5px;margin:5px 0px 5px 20px; }
.privacy-policy h3{color: red;text-align: justify;}

@media(max-width:767px){
    .privacy-policy .heading{font-size:20px;}
    .privacy-policy {font-size:14px;line-height:22px;}
}