.footer{background-color: #1b182f;width: 100%;margin: 80px auto 0;padding:20px;}
.footer .top-footer{display: flex;justify-content:space-around;color: white;font-weight: 700;border-bottom: 1px solid #f5f5f533;padding:0 0 20px;}
.footer .top-footer .email,
.footer .top-footer .number{color:#fff;word-break: break-word;font-size:16px;line-height:20px;}
.footer .bottom-footer{display: flex;max-width: 990px;width: 100%;margin: 0px auto;column-gap: 130px;color: #838ea7;padding: 50px 0px;}
.footer .bottom-footer .left{text-align: justify;display: flex;flex-direction: column;row-gap:10px;}
.footer .bottom-footer .left .para{font-size:14px;line-height:22px;text-align: left;}
.footer .center{text-align: justify;display: flex;flex-direction: column;row-gap:10px;}
.footer .right{text-align: justify;display: flex;flex-direction: column;row-gap:10px;}
.footer .link-list{display: flex;flex-direction: column;row-gap:10px;}
.footer .link-list .link{color:#838ea7;font-size:14px;}
.footer .name{color: white;font-size: 20px;font-weight:600;}
.footer .copyright{color: white;font-weight: 500;text-align:center;padding:25px 15px;}
.anchor{font-weight: 600;text-decoration: none;}
.footer .copyright:hover{color: skyblue;cursor: pointer;}

@media(max-width:991px){
    .footer .bottom-footer{column-gap:100px;}
}
@media(max-width:767px){
    .footer .bottom-footer{column-gap:80px;}
}
@media(max-width:576px){
    .footer .bottom-footer{column-gap:40px;}
}
@media(max-width:479px){
    .footer .bottom-footer{flex-direction:column;align-items:flex-start;row-gap:20px;}
    .footer .name{font-size:18px;}
    .footer .top-footer .email,
    .footer .top-footer .number{font-size:14px;}
    .footer .copyright{font-size:14px;line-height:24px;}
}